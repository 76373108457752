body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; }
body, button, input, select, textarea { font:12px/1.5tahoma, arial, \5b8b\4f53; }
h1, h2, h3, h4, h5, h6{ font-size:100%; }
address, cite, dfn, em, var { font-style:normal; }
code, kbd, pre, samp { font-family:couriernew, courier, monospace; }
small{ font-size:12px; }
ul, ol { list-style:none; }
a { text-decoration:none; }
a:hover { text-decoration:underline; }
sup { vertical-align:text-top; }
sub{ vertical-align:text-bottom; }
legend { color:#000; }
fieldset, img { border:0; }
button, input, select, textarea { font-size:100%; }
table { border-collapse:collapse; border-spacing:0; }
@font-face{
  font-weight:normal;
  font-style:normal;
  font-family: 'Slogan Font';
  src: url('https://tc-cdn.123u.com/official-website/font/ZiTiQuanXinYiGuanHeiTi.eot');
  src: url('https://tc-cdn.123u.com/official-website/font/ZiTiQuanXinYiGuanHeiTi.eot') format('embedded-opentype'),
       url('https://tc-cdn.123u.com/official-website/font/ZiTiQuanXinYiGuanHeiTi.ttf') format('truetype');
}

@font-face{
  font-weight:normal;
  font-style:normal;
  font-family: 'Slogan Font English';
  src: url('https://tc-cdn.123u.com/official-website/font/TCCEB.eot');
  src: url('https://tc-cdn.123u.com/official-website/font/TCCEB.eot') format('embedded-opentype'),
       url('https://tc-cdn.123u.com/official-website/font/TCCEB.TTF') format('truetype');
}

body{
  min-width: 300px;
  font-size: 14px;
  font-family: 'Microsoft YaHei';
}