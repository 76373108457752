.Map{
  img{
    width: 100%;
    height: auto;
  }
}
.ContactContainer {
  background: #f4f4f4;
  padding: 80px 0;
  color: #171717;
  display: flex;
  align-items: center;
  flex-direction: column;
  .PCInfo{
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1188px;
    .CardWrap {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-right: -36px;
      margin-bottom: -20px;
      .Item {
        width: 360px;
        height: 230px;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        margin-right: 36px;
        .ItemTitle {
          height: 100px;
          border-bottom: 1px solid #f4f4f4;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          color: #171717;
          font-size: 24px;
          line-height: 1;
          .VerticalLine{
            height: 28px;
            width: 6px;
            border-radius: 3px;
            background-color: #f18101;
            margin-right: 10px;
          }
          > img {
            height: 52px;
            width: auto;
            display: block;
            margin-bottom: 30px;
          }
        }
        .ItemContentWrap{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: auto 0;
          .ItemContent {
            font-size: 16px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
      .QrCode {
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;
        width: 230px;
        height: 230px;
        background-color: white;
        .Row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          height: 115px;
          width: 230px;
          img {
            width: auto;
            height: 115px;
          }
        }
      }
    }
  }
  .MobileInfo{
      display: none;
  }
}
@media screen and (max-width: 1200px) {
  .ContactContainer {
   .CardWrap {
     max-width: 792px;
   }
   .MobileInfo{
    display: none;
    } 
  }
}
@media screen and (max-width: 956px) {
  .ContactContainer {
    padding: 20px 0;
    .MobileInfo{
      display: none;
      }
   }
}
@media screen and (max-width: 800px) {
  .ContactContainer {
    .PCInfo{
      display: none;
    }
    .MobileInfo{
      display: flex;
      flex-direction: column;
      align-items: center;
      .Info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        width:80vw;
        margin-top: 20px;
        padding:20px;
        border-radius: 10px;
        box-shadow: 0 1px 5px #cdcdcd;
        .Left{
          width: calc(10vw + 10px);
          height:10vw;
          font-size:4vw;
          text-align: center;
          line-height: 5vw;
          border-right: 2px solid #f18101;
          padding-right: 10px;
        }
        .Right{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
      .QrCode {
        margin: 0 auto;
        margin-top: 20px;
        width: 80vw;
        background-color: white;
        .Row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          width: 80vw;
          height: 40vw;
          img {
            width:auto;
            height: 40vw;
          }
        }
        .QrCodeTip {
          color: #999;
          font-size: 14px;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
}